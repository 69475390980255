import React from 'react'
import { Embed, Grid, List, Image, Header, Item } from 'semantic-ui-react'


function InventoryValueTracker () {

    return(
        <Grid    style={{marginTop:'unset',  padding:'20px'}} columns='equal'>
        <Grid.Row>
        <Grid.Column >                    
            </Grid.Column>
            <Grid.Column />
            <Grid.Column >                                    
            </Grid.Column>                
        </Grid.Row>
        <Grid.Row>
            <Grid.Column />
            <Grid.Column width={12}>
                <Image centered size='medium' src='ivt/ivt1.jpg' /> 
                <Header as='h2'>
                     <span style={{color:"white"}}>Inventory Value Tracker for Shopify</span>
                </Header>
                <p>While running my own company I had been using Shopify as the e-commerce platform and while it was great and had a ton of add-ons available, 
                    one thing that was missing was a simple way to track the value of your inventory. I didn't want to purchase a large feature-rich
                    add-on, I just wanted an extra field for the cost of goods and a few reports. </p>
                <p>It turns out that many folks had been asking for this but Shopify never acted, so I decided to build it myself.</p>
                <p>Sadly, as I was going through the review process to get it added to the Shopify store, Shopify actually released this feature which
                    kind of did everything my add-on did so.... let's just say there were no monetary rewards for my efforts ;p  
                </p>
                <Header as='h3' dividing color='teal'>
                    Tech Stack
                </Header>
                <List>
                    <List.Item>
                        <List.Icon name='marker' color='teal'/>
                        <List.Content>
                            Python / Django 
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='marker' color='teal'/>
                        <List.Content>
                            Shopify webhooks, metadata, oauth 
                        </List.Content>
                    </List.Item>                       
                </List>
                <Header as='h3' dividing color='teal'>
                    Features
                </Header>
                <List>
                    <List.Item>
                        <List.Icon name='marker' color='teal'/>
                        <List.Content>
                            When IVT was added to a Shopify store, it would add new meta fields to your products to attach the cost of goods. You 
                            could navigate to the IVT website to view reports or even update values. The app employed Shopify webhooks to listen for 
                            product changes as well. 
                        </List.Content>
                    </List.Item>
                </List>
                <Header as='h3' dividing color='teal'>
                    Repositories
                </Header>
                <List>
                    <List.Item>                    
                        <List.Icon name='github' color='teal'/>
                        <List.Content>
                            <a href="https://github.com/joannpav/COGS"  style={{color:"white"}}> Github - email me for access</a>
                        </List.Content>
                    </List.Item>                    
                </List>
                
                <Header as='h3' dividing color='teal'>
                    Demo
                </Header>
                <Embed
                    id='zSdc2mnzlqM'
                    placeholder='ivt/splash1.png'
                    source='youtube'                    
                /> 
                 <Embed
                    id='yNLD_ckBY3U'
                    placeholder='ivt/splash2.png'
                    source='youtube'                    
                /> 
                <Header as='h3' dividing color='teal'>
                    Screenshots
                </Header>
                <Image src='/ivt/Screenshot_desktop_1.png' wrapped ui={true} />     
                <Image src='/ivt/Screenshot_desktop_5.jpg' wrapped ui={true} />    
                <Image src='/ivt/Screenshot_desktop_4.jpg' wrapped ui={true} />  
                <Image src='/ivt/Screenshot_desktop_3.png' wrapped ui={true} />     
                <Header as='h3' dividing color='teal'>
                    Motivation / Challenges
                </Header>
                <Item.Group relaxed>
                    <Item>
                        <Item.Content>
                            <Item.Header style={{color:"white"}}>Motivation</Item.Header>
                            <Item.Description style={{color:"white", fontSize:"medium"}}>
                                <p>
                                    I was trying to solve a problem I had and thought it had potential to 
                                    generate revenue as well since many folks had been asking for this feature but 
                                    Shopify had not confirmed or denied it would ever be on the road map.

                                </p>
                                
                            </Item.Description>
                        </Item.Content>
                    </Item>
                    <Item>
                        <Item.Content>
                            <Item.Header style={{color:"white"}}>Challenges</Item.Header>
                            <Item.Description>
                            <List.Item>                                
                                <List.Content style={{color:"white", fontSize:"medium"}}>
                                    <p>
                                    Getting the app approved was the biggest challenge. At the time I submitted, Shopify was transitioning to a new 
                                    store for add-ons, so I had to fill out both an application for the old and the new store. The minimum time for someone to 
                                    review your app was about a month if I recall correctly. 
                                    </p>
                                    <p>Once my app got in front of a reviewer, they were super nitpicky about many things, this caused us to go back and forth
                                        several times to make adjustments. 
                                    </p>
                                    <p>
                                        When it was finally approved, Shopify released their cost of goods implementation essentially making my app obsolete.
                                    </p>
                                    <p>
                                        Lesson learned: don't rely on a third party ecosystem to build a business. (This probably would not have been a real business 
                                        for me but I thought it had potential to generate some revenue when I first started building it.)
                                    </p>
                                </List.Content>
                            </List.Item>
                            </Item.Description>
                        </Item.Content>
                    </Item>
                </Item.Group>
            </Grid.Column>
            <Grid.Column >                
                     
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column />
            <Grid.Column />
            <Grid.Column >                
                
            </Grid.Column>                
        </Grid.Row>
    </Grid>
    )


}

export default InventoryValueTracker;