import React, {useState} from "react";
import { Container, Grid } from 'semantic-ui-react';
import SideBar from "../components/SideBar";
import TestChat from "./TestChat";
import ProfitCrier from "./ProfitCrier";
import RentalRank from "./RentalRank";
import AutoTube from "./AutoTube";
import InventoryValueTracker from "./InventoryValueTracker";
import Powervine from "./Powervine";
import Dashly from "./Dashly";


function Projects() {
    const [activeItem, setActiveItem] = useState("testchat");

    const loadActive = (() => {
        if (activeItem === 'testchat') { return ( <TestChat /> )}
        if (activeItem === 'profitcrier') { return <ProfitCrier />}
        if (activeItem === 'rentalrank') { return <RentalRank />}
        if (activeItem === 'autotube') { return <AutoTube />}
        if (activeItem === 'inventory') { return <InventoryValueTracker />}
        if (activeItem === 'powervine') { return <Powervine />}
        if (activeItem === 'dashly') { return <Dashly />}
        
      })

    return (  
        <Container className="App-header" style={{width: 'auto'}}>
            <Grid>
                <Grid.Column width="16">
                <SideBar setActiveItem={setActiveItem} />                    
                    {loadActive()}
                </Grid.Column>
                
            </Grid>
        </Container>             
    )
}


export default Projects;