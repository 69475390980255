import React from 'react'
import { Item, Grid, List, Image, Header, Embed } from 'semantic-ui-react'

function RentalRank () {

    return(
        <Grid    style={{marginTop:'unset',  padding:'20px'}} columns='equal'>
        <Grid.Row>
        <Grid.Column >                    
            </Grid.Column>
            <Grid.Column />
            <Grid.Column >                                    
            </Grid.Column>                
        </Grid.Row>
        <Grid.Row>
            <Grid.Column />
            <Grid.Column width={12}>
                <Image size='big' src='rentalrank/RRTwitterBG.png' /> 
                <Header as='h2'>
                    <span style={{color:"white"}}>RentalRank</span>
                </Header>
                <p>Common problems for short term rental property owners is that their listings stop appearing in 
                    the Airbnb search results. Airbnb only lists 300 properties at a time for any area, so how do you
                    stand out? 
                </p>
                <p>
                    RentalRank was designed to monitor Airbnb & Booking.com search results and show you where your
                    property ranked in the listings. If your property did not rank for 3 consecutive days, it would send
                    you an email. 

                </p>
                <Header as='h3' dividing color='teal'>
                    Tech Stack
                </Header>
                <List>
                    <List.Item>
                        <List.Icon name='marker' color='teal'/>
                        <List.Content>
                            Frontend: Lightsail
                            - React
                            - Javascript
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='marker' color='teal'/>
                        <List.Content>
                        Backend: Lightsail 
                            - Python DRF
                            - Postgres DB
                            - Amazon Lightsail
                            - Nginx, Gunicorn
                        </List.Content>
                    </List.Item>                                       
                    
                </List>
                <Header as='h3' dividing color='teal'>
                    Features
                </Header>
                <List>
                    <List.Item>
                        <List.Icon name='marker' color='teal'/>
                        <List.Content>
                            Monitor your ranking over time
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='marker' color='teal'/>
                        <List.Content>
                            Monitor review counts and scores over time 
                        </List.Content>
                    </List.Item>  
                    <List.Item>
                        <List.Icon name='marker' color='teal'/>
                        <List.Content>
                            Compare your property listing to the top property listing in your city
                        </List.Content>
                    </List.Item>                       
                    <List.Item>
                        <List.Icon name='marker' color='teal'/>
                        <List.Content>
                            See if your property shows up in Google search results 
                        </List.Content>
                    </List.Item>  
                    <List.Item>
                        <List.Icon name='marker' color='teal'/>
                        <List.Content>
                            Check your ranking for any open upcoming dates and see how much competition you have
                        </List.Content>
                    </List.Item>  
                </List>
                <Header as='h3' dividing color='teal'>
                    Repositories
                </Header>
                <List>
                    <List.Item>                    
                        <List.Icon name='github' color='teal'/>
                        <List.Content>
                            <a href="https://github.com/joannpav/vrranker"  style={{color:"white"}}> Github - email me for access</a>
                        </List.Content>
                    </List.Item>                    
                </List>
                <Header as='h3' dividing color='teal'>
                    Demo
                </Header>
                <Embed
                    id='CWZyZmMM7qM'
                    placeholder='rentalrank/RRTwitterBG.png'
                    source='youtube'                      
                />
                <Header as='h3' dividing color='teal'>
                    Motivation / Challenges
                </Header>
                <Item.Group relaxed>
                    <Item>
                        <Item.Content>
                            <Item.Header style={{color:"white"}}>Motivation</Item.Header>
                            <Item.Description style={{color:"white", fontSize:"medium"}}>
                                <p>
                                    I started this project because I wanted to know how my rental property
                                    was doing on different platforms compared to other properties in my area.
                                </p>
                            </Item.Description>
                        </Item.Content>
                    </Item>
                    <Item>
                        <Item.Content>
                            <Item.Header style={{color:"white"}}>Challenges</Item.Header>
                            <Item.Description>
                            <List.Item>                                
                                <List.Content style={{color:"white", fontSize:"medium"}}>
                                    <p>
                                    Airbnb only opens their API to established companies so I had to gather all the data via screen-scraping. If you watch
                                    the video, you'll notice that some of the images are missing as well as some of the data points. This is because when 
                                    screenscraping is used for gathering data, it requires constant up-keep as small changes to the UI could cause problems. 
                                    Other changes such as a new popup to advertise a deal or a notification of upcoming system maintenance would also break the 
                                    data gathering.                                
                                    </p><p>                                    
                                    Another reason that I abandoned this project was that the information I was gathering was interesting but not actionable. For example, if my property is only showing up on page 20 of of the listings, other than updating rates to be very low, there was not a lot of actionable things to do. 
                                    While this tool could be potentially useful in catching things such as your property not being listed because you set a minimum booking of 5 nights for example, the use cases weren’t compelling enough for the effort of maintaining it.
                                    </p><p>
                                    If Airbnb had an open API, it may have made this project more feasible.
                                    </p>
                                </List.Content>
                            </List.Item>
                            </Item.Description>
                        </Item.Content>
                    </Item>
                </Item.Group>
            </Grid.Column>
            <Grid.Column >                
                     
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column />
            <Grid.Column />
            <Grid.Column >                
                
            </Grid.Column>                
        </Grid.Row>
       

    </Grid>
    )


}

export default RentalRank;