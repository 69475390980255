import React from 'react'
import { Grid, List, Image, Header } from 'semantic-ui-react'

function Dashly () {

    return(
        <Grid    style={{marginTop:'unset',  padding:'20px'}} columns='equal'>
        <Grid.Row>
        <Grid.Column >                    
            </Grid.Column>
            <Grid.Column />
            <Grid.Column >                                    
            </Grid.Column>                
        </Grid.Row>
        <Grid.Row>
            <Grid.Column />
            <Grid.Column width={12}>
                
                <Header as='h2'>
                    <span style={{color:"white"}}>Dashly</span>
                </Header>
                <p>One of the big problems a QA group has is getting more visibility around test automation results as well as enabling
                    teams to independently run and review results. Because each team has their own servers for deploying build for testing, 
                    we want to ensure these environments are stable and running the latest builds.  
                </p>
                <p>
                    What could make them unstable? Infrastructure updates. Data accumulation. Failures of services. There could be many things.
                    To mitigate this risk, we run a full automated regression nightly. A team should be able to look at their server and know
                    that if they deploy their changes and a test fails, it is a real failure of either product or test code and needs to fixed. 
                </p>
                <p>
                    But how do teams know if their server is stable? That's where Dashly comes in. It bubbles up automation results from 
                    Jenkins giving each team a solid understanding of the stability of their server, and some insight into which tests are actually
                    flakey and need to be fixed. Dashly also gives the team control to kick off test runs and review Allure test run reports. 
                </p>
                <p>
                    Why not just let everyone log into Jenkins? Jobs in Jenkins are parameterized, so a single job will be running tests for 15 or
                    more environments. So it is possible to dive in and get those results, it is hard to see trends for a specific environment. The
                    Jenkins results don't give a clear view of a server, the build it is running and the current state. That is what Dashly does. 
                </p>
                <p>
                    I conceptualized this app for a hackathon project which placed in the top 3 for our category. I re-wrote the original UI that was 
                    developed during the hackathon by another developer. The updated UI is shown below.
                </p>
                <Header as='h3' dividing color='teal'>
                    Tech Stack
                </Header>
                <List>
                    <List.Item>
                        <List.Icon name='marker' color='teal'/>
                        <List.Content>
                            Frontend: 
                            - React
                            - Javascript
                            - Semantic UI
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='marker' color='teal'/>
                        <List.Content>
                        Backend:  
                            - C#
                            - MS SQL                            
                        </List.Content>
                    </List.Item>                                       
                    
                </List>
                <Header as='h3' dividing color='teal'>
                    Features
                </Header>
                <List>
                    <List.Item>
                        <List.Icon name='marker' color='teal'/>
                        <List.Content>
                            Dashboard
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='marker' color='teal'/>
                        <List.Content>
                            Kick off test automation
                        </List.Content>
                    </List.Item>  
                    <List.Item>
                        <List.Icon name='marker' color='teal'/>
                        <List.Content>
                            View Allure reports
                        </List.Content>
                    </List.Item>                       
                    <List.Item>
                        <List.Icon name='marker' color='teal'/>
                        <List.Content>
                            View top test failures, slowest tests, pass, fail, skipped, quarantine trends over time
                        </List.Content>
                    </List.Item>  
                      
                </List>                
                <Header as='h3' dividing color='teal'>
                    Screenshots
                </Header>
                <Image src='/dashly/dashly.jpg' wrapped ui={true} />
                
            </Grid.Column>
            <Grid.Column >                
                     
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column />
            <Grid.Column />
            <Grid.Column >                
                
            </Grid.Column>                
        </Grid.Row>
       

    </Grid>
    )


}

export default Dashly;