import React from "react";
import { Container, List, Grid } from 'semantic-ui-react';

function Skills() {
    return (  
        <Container className="App-header" style={{width: 'auto'}}>
        <Grid    style={{marginTop:'unset',  padding:'20px'}} columns='equal'>
            <Grid.Row>
            <Grid.Column />
            <Grid.Column >
                    <Container text >
                        <h2>Languages and frameworks that I have used professionally or in personal projects</h2>
                        <i>Expertise levels vary </i>
                    <List>
                        <List.Item style={{color:"teal", fontSize:"large"}}>
                            <List.Icon name='cogs' />
                            <List.Content>Test Automation</List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Icon name='marker' />
                            <List.Content>Selenium</List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Icon name='marker' />
                            <List.Content>Cypress</List.Content>
                        </List.Item>
                        <List.Item />
                        <List.Item style={{color:"teal", fontSize:"large"}}>
                            <List.Icon name='code' />
                            <List.Content>Languages & Frameworks</List.Content>
                        </List.Item>
                        
                        <List.Item>
                            <List.Icon name='marker' />
                            <List.Content>Python</List.Content>                            
                        </List.Item>
                        <List.Item>
                            <List.Icon name='marker' />
                            <List.Content>Javascript</List.Content>                            
                        </List.Item>
                        <List.Item>
                            <List.Icon name='marker' />
                            <List.Content>C#</List.Content>                            
                        </List.Item>
                        <List.Item>
                            <List.Icon name='marker' />
                            <List.Content>Java</List.Content>                            
                        </List.Item>
                        
                        <List.Item>
                            <List.Icon name='marker' />
                            <List.Content>React</List.Content>                            
                        </List.Item>
                        <List.Item>
                            <List.Icon name='marker' />
                            <List.Content>Django</List.Content>                            
                        </List.Item>
                        <List.Item>
                            <List.Icon name='marker' />
                            <List.Content>GraphQL</List.Content>                            
                        </List.Item>
                        <List.Item>
                            <List.Icon name='marker' />
                            <List.Content>Django Rest Framework</List.Content>                            
                        </List.Item>
                        
                        
                        
                    </List>
                </Container>
                </Grid.Column>
                <Grid.Column />
                              
            </Grid.Row>
            
        </Grid>
        </Container>             
    )
}


export default Skills;