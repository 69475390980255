import React from 'react'
import { Grid, List, Header, Embed, Item } from 'semantic-ui-react'

function ProfitCrier () {

    return(
        <Grid    style={{marginTop:'unset',  padding:'20px'}} columns='equal'>
        <Grid.Row>
        <Grid.Column >                    
            </Grid.Column>
            <Grid.Column />
            <Grid.Column >                                    
            </Grid.Column>                
        </Grid.Row>
        <Grid.Row>
            <Grid.Column />
            <Grid.Column width={12}>
                <Header as='h1'>
                    <span style={{color:"white"}}>ProfitCrier.io</span>
                </Header>
                <p>Solves the problem of understanding when you've crossed the threshold into profitability for your
                    vacation rental property managed by Vacasa. </p>
                <Header as='h3' dividing color='teal'>
                    Tech Stack
                </Header>
                <List>
                    <List.Item>
                        <List.Icon name='marker' color='teal'/>
                        <List.Content>
                        Frontend: Netlify
                            - Svelte
                            - Javascript
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='marker' color='teal'/>
                        <List.Content>
                        Backend: Lightsail 
                            - Python DRF
                            - Postgres DB
                            - Amazon Lightsail
                            - Nginx, Gunicorn
                        </List.Content>
                    </List.Item> 
                    <List.Item>
                        <List.Icon name='marker' color='teal'/>
                        <List.Content>
                            Notifications: Pusher.com
                            IOS App
                        </List.Content>
                    </List.Item>                       
                    <List.Item>
                        <List.Icon name='marker' color='teal'/>
                        <List.Content>
                            Chrome Extension
                                - Svelte
                                - Javascript
                        </List.Content>
                    </List.Item>                       

                </List>
                <Header as='h3' dividing color='teal'>
                    Features
                </Header>
                <List>
                    <List.Item>
                        <List.Icon name='marker' color='teal'/>
                        <List.Content>
                            Configure your fixed costs, we calculate your profits
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='marker' color='teal'/>
                        <List.Content>
                            New bookings send an alert to IOS showing updated profits and letting you know if you are in the green!
                        </List.Content>
                    </List.Item>                      
                </List>
                <Header as='h3' dividing color='teal'>
                    Repositories - email me for access
                </Header>
                <List>
                    <List.Item>                    
                        <List.Icon name='github' color='teal'/>
                        <List.Content>
                            <a href="https://github.com/joannpav/profitcrierBE"  style={{color:"white"}}> Backend</a>
                        </List.Content>
                    </List.Item>
                    <List.Item>                    
                        <List.Icon name='github' color='teal'/>
                        <List.Content>
                            <a href="https://github.com/joannpav/profitcrierPWA"  style={{color:"white"}}> Frontend</a>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='github' color='teal' />
                        <List.Content>
                            <a href="https://github.com/joannpav/ProfitCrierIOS" style={{color:"white"}}> IOS</a>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='github' color='teal' />
                        <List.Content>
                            <a href="https://github.com/joannpav/ProfitCrierChromeExtension" style={{color:"white"}}> Chrome Extension</a>
                        </List.Content>
                    </List.Item>
                </List>
                <Header as='h3' dividing color='teal'>
                    Demo
                </Header>
                <Embed
                    id='SKE7XNMMI08'
                    placeholder='profitcrier/profitcrier2.png'
                    source='youtube'                    
                />        
                <Header as='h3' dividing color='teal'>
                    Motivation / Challenges
                </Header>
                <Item.Group relaxed>
                    <Item>
                        <Item.Content>
                            <Item.Header style={{color:"white"}}>Motivation</Item.Header>
                            <Item.Description style={{color:"white", fontSize:"medium"}}>
                                <p>
                                    I started this project because I was tracking that information manually and 
                                    thought there must be a better way.
                                </p><p>
                                    I was interested in learning Svelte, so this seemed like a good project for the
                                    front end. Initially it was only going to be a Chrome extension which is what I 
                                    wrote first, but then after some though, it seemed more useful if the app could 
                                    simply notify me when I had a new booking, so repurposed some of the code and wrote
                                    the app. 
                                </p>
                            </Item.Description>
                        </Item.Content>
                    </Item>
                    <Item>
                        <Item.Content>
                            <Item.Header style={{color:"white"}}>Challenges</Item.Header>
                            <Item.Description>
                            <List.Item>                                
                                <List.Content style={{color:"white", fontSize:"medium"}}>
                                    <p>
                                    Vacasa does not have a public API nor do they have webhooks to subscribe to. This meant
                                    that I had to employ some screen-scraping to do the initial login. Once logged I was able to
                                    grab the JWT token and then use it to send further API requests.
                                    </p><p>
                                    With the Chrome extension, as long as the user logged in on another tab, the token could be
                                    accessed for further API requests. 
                                    </p><p>
                                    I really wanted to get a notification on my phone when a new booking occurred. The notification 
                                    displayed the new booking details along with the current profit/loss for the month. Since I 
                                    had no experience with building an IOS app, I definitely struggled. I was successful in building the 
				                    notification app and hooking it up to Pusher.com to handle pushing the message, but I did not end 
                                    up creating the rich message that I had been thinking about. At one point, I looked in to Flutter and
                                    thought maybe it would be easier to rewrite it in a cross-platform solution like that, but I abandoned that
                                    idea after realizing that I just didn’t want to put in the time to learn Dart.
                                    </p><p>
                                    I ended up deploying a working version of this app using Amazon Lightsail for the backend and Netlify for the frontend.
                                    Since Vacasa does not have a webhook to subscribe to new events, I had to have my backend login and check
                                    for new bookings periodically. While this worked, it was less than ideal.
                                    </p>
                                </List.Content>
                            </List.Item>
                            </Item.Description>
                        </Item.Content>
                    </Item>
                </Item.Group>
                        
            </Grid.Column>
            <Grid.Column />                                
        </Grid.Row>
        
    </Grid>
    )


}

export default ProfitCrier;