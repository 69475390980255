import React from 'react'
import { Grid, List, Embed, Header, Item } from 'semantic-ui-react'


function Powervine () {

    return(
        <Grid    style={{marginTop:'unset',  padding:'20px'}} columns='equal'>
        <Grid.Row>
        <Grid.Column >                    
            </Grid.Column>
            <Grid.Column />
            <Grid.Column >                                    
            </Grid.Column>                
        </Grid.Row>
        <Grid.Row>
            <Grid.Column />
            <Grid.Column width={12}>
                <Header as='h2'>
                     <span style={{color:"white"}}>Powervine</span>
                </Header>
                <p>Powervine was a Chrome extension that I conceptualized and built with a few others as part of a hackathon project. </p>
                <Header as='h3' dividing color='teal'>
                    Tech Stack
                </Header>
                <List>
                    <List.Item>
                        <List.Icon name='marker' color='teal'/>
                        <List.Content>
                            React 
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='marker' color='teal'/>
                        <List.Content>
                            Chrome Extenstion Manifest 3
                            - Messaging
                            - Storage
                            - Context menus
                        </List.Content>
                    </List.Item>                       
                </List>
                <Header as='h3' dividing color='teal'>
                    Features
                </Header>
                <List>
                    <List.Item>
                        <List.Icon name='marker' color='teal'/>
                        <List.Content>
                            The premise of this app was that it would keep the feed of another app which was consumed via API 
                            next to you all day no matter what website you were browsing.
                        </List.Content>
                    </List.Item>
                 </List>   
                
                <Header as='h3' dividing color='teal'>
                    Demo
                </Header>
                <Embed
                    id='w3OA-b2vF_E'
                    placeholder='powervine/pvsplash2.png'
                    source='youtube'                    
                /> 
                
                <Header as='h3' dividing color='teal'>
                    Motivation / Challenges
                </Header>
                <Item.Group relaxed>
                    <Item>
                        <Item.Content>
                            <Item.Header style={{color:"white"}}>Challenges</Item.Header>
                            <Item.Description>
                            <List.Item>                                
                                <List.Content style={{color:"white", fontSize:"medium"}}>
                                    <p>
                                    This was my first foray into the world of Chrome extensions, so had to learn how it worked and 
                                    deliver a working product for the hackathon in a 
                                    very short time period (4 days).
                                    </p>                                    
                                </List.Content>
                            </List.Item>
                            </Item.Description>
                        </Item.Content>
                    </Item>
                </Item.Group>
            </Grid.Column>
            <Grid.Column >                
                     
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column />
            <Grid.Column />
            <Grid.Column >                
                
            </Grid.Column>                
        </Grid.Row>
    </Grid>
    )


}

export default Powervine;