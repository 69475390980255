import React, { Component } from 'react'
import { Image, Menu, Segment } from 'semantic-ui-react'
import Work from '../pages/Work'
import Projects from '../pages/Projects';
import Skills from '../pages/Skills';

export default class MenuBar extends Component {
  state = { activeItem: 'home' }

  handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  
  render() {
    const { activeItem } = this.state

    const loadActive = (() => {
        if (activeItem === 'home') { return (
            <>
            <Image src='skiing.jpg' fluid/>
                <div className="bg-image"></div>
                <div className="bg-text">
                <h1>Hello.</h1>
                <h1>I'm Joann</h1>
                </div>    
            </>
        )}
        if (activeItem === 'work') { return <Work />}
        if (activeItem === 'projects') { return <Projects />}
        if (activeItem === 'skills') { return <Skills />}
      })

    return (
      <div>
        <Menu pointing secondary>
          <Menu.Item
            name='home'
            active={activeItem === 'home'}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            name='work'
            active={activeItem === 'work'}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            name='projects'
            active={activeItem === 'projects'}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            name='skills'
            active={activeItem === 'skills'}
            onClick={this.handleItemClick}
          />
          
        </Menu>

        <Segment>
            {loadActive()}
            
          
        </Segment>
      </div>
    )
  }
}
