import React from 'react';

import { Container } from 'semantic-ui-react';
import MenuBar from './components/MenuBar';



import './App.css';

function App() {
  return (
          
      <Container fluid>
        <MenuBar />
               
      </Container>
    
    );
}

export default App;
