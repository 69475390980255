import React from 'react'
import { Menu } from 'semantic-ui-react'

function SideBar({setActiveItem}) {    
    var activeItem;
    const handleItemClick = (e, { name }) => setActiveItem(() => name);

    return (
        
      <Menu  inverted>
        <Menu.Item          
          name='testchat'
          active={activeItem === 'testchat'}
          onClick={handleItemClick}
        >         
          TestChat
        </Menu.Item>

        <Menu.Item        
          name='profitcrier'          
          active={activeItem === 'profitcrier'}
          onClick={handleItemClick}
        >
          
          ProfitCrier
        </Menu.Item>

        <Menu.Item
          name='rentalrank'
          active={activeItem === 'rentalrank'}
          onClick={handleItemClick}
        >
          
          RentalRank
        </Menu.Item>
        <Menu.Item
          name='autotube'
          active={activeItem === 'autotube'}
          onClick={handleItemClick}
        >
          
          Autotube
        </Menu.Item>
        <Menu.Item
          name='dashly'
          active={activeItem === 'dashly'}
          onClick={handleItemClick}
        >
          
          Dashly
        </Menu.Item>
        <Menu.Item
          name='powervine'
          active={activeItem === 'powervine'}
          onClick={handleItemClick}
        >
          
          Powervine
        </Menu.Item>
        <Menu.Item
          name='inventory'
          active={activeItem === 'inventory'}
          onClick={handleItemClick}
        >
          
          Inventory Value Tracker for Shopify
        </Menu.Item>
      </Menu>
      
    )
  }

  export default SideBar;
