import React from 'react'
import { Grid, List, Image, Header, Item } from 'semantic-ui-react'


function TestChat () {

    return(
        <Grid    style={{marginTop:'unset',  padding:'20px'}} columns='equal'>
        <Grid.Row>
        <Grid.Column >                    
            </Grid.Column>
            <Grid.Column />
            <Grid.Column >                                    
            </Grid.Column>                
        </Grid.Row>
        <Grid.Row>
            <Grid.Column />
            <Grid.Column width={12}>
                <Image centered circular src='TestChat512.png' size="medium"/>

                <Header as='h2'>
                     <span style={{color:"white"}}>TestChat.io</span>
                </Header>
                <p>Bridges the communication gap between developers, testers & production owners promoting a better way to <i>Shift Left and Test</i> </p>
                <Header as='h3' dividing color='teal'>
                    Tech Stack
                </Header>
                <List>
                    <List.Item>
                        <List.Icon name='marker' color='teal'/>
                        <List.Content>
                            React / Semantic UI
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='marker' color='teal'/>
                        <List.Content>
                            GraphQL / MongoDB / Apollo 
                        </List.Content>
                    </List.Item>                       
                </List>
                <Header as='h3' dividing color='teal'>
                    Features
                </Header>
                <List>
                    <List.Item>
                        <List.Icon name='marker' color='teal'/>
                        <List.Content>
                            Create epics and stories manually or import from Jira
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='marker' color='teal'/>
                        <List.Content>
                            Build test scenarios 
                        </List.Content>
                    </List.Item>  
                    <List.Item>
                        <List.Icon name='marker' color='teal'/>
                        <List.Content>
                            Approve / Disapprove / Comment on test scenarios
                        </List.Content>
                    </List.Item>                       
                    <List.Item>
                        <List.Icon name='marker' color='teal'/>
                        <List.Content>
                            Export test scenarios to Cypress integration files
                        </List.Content>
                    </List.Item>  
                </List>
                <Header as='h3' dividing color='teal'>
                    Screenshots
                </Header>
                <Image src='/testchat/testchat_testscenarios.png' wrapped ui={true} />
                <Header as='h3' dividing color='teal'>
                    Motivation / Challenges
                </Header>
                <Item.Group relaxed>
                    <Item>
                        <Item.Content>
                            <Item.Header style={{color:"white"}}>Motivation</Item.Header>
                            <Item.Description style={{color:"white", fontSize:"medium"}}>
                                <p>
                                    Having worked as an SDET as well as managed SDETs & QAEs, I’ve seen a recurring theme. Unless the team is fully agile meaning that they are conducting all the agile ceremonies and truly self organizing with the goal of delivering the work defined for the sprint they often fall into a familiar routine of the developer writing code in isolation and then throwing it over the ‘wall’ to the SDET/QAE to test.                                 </p>
                                <p>
                                    At this point, the coding is done so any bugs found go back to the developer who has likely moved on to the next new thing. In addition, if the feature has been found to be difficult to test, it is much harder at this point to have a conversation with the developer to start talking about building in testability. 
                                </p><p>How do we make this work better? The SDET/QAE need to know what the story and acceptance criteria are when development starts. They should ask clarifying questions to the PO. They should begin developing test scenarios and share that with the team. By sharing test scenarios, the developer may be made aware of cases that they had not thought about and can account for that in code. The developer/SDET can discuss approaches for implementing unit, integration and end to end tests and ensure testing is optimized at the right levels.
                                </p><p>
                                    TestChat helps facilitate these conversations without forcing the team into yet another meeting. TestChat is a social-like network for enabling teams to shift left and test.
                                </p>
                            </Item.Description>
                        </Item.Content>
                    </Item>
                    <Item>
                        <Item.Content>
                            <Item.Header style={{color:"white"}}>Challenges</Item.Header>
                            <Item.Description>
                            <List.Item>                                
                                <List.Content style={{color:"white", fontSize:"medium"}}>
                                    <p>
					                    I’ve used this project as an opportunity to learn GraphQL / MongoDB / Apollo as well as a new-to-me frontend framework, Semantic UI, so the challenges thus far are simply the typical learning curve around learning anything new.
                                    </p>
                                </List.Content>
                            </List.Item>
                            </Item.Description>
                        </Item.Content>
                    </Item>
                </Item.Group>
            </Grid.Column>
            <Grid.Column >                
                     
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column />
            <Grid.Column />
            <Grid.Column >                
                
            </Grid.Column>                
        </Grid.Row>
    </Grid>
    )


}

export default TestChat;