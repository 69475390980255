import React from 'react'
import { Grid, List, Image, Header, Item } from 'semantic-ui-react'


function AutoTube () {

    return(
        <Grid    style={{marginTop:'unset',  padding:'20px'}} columns='equal'>
        <Grid.Row>
        <Grid.Column >                    
            </Grid.Column>
            <Grid.Column />
            <Grid.Column >                                    
            </Grid.Column>                
        </Grid.Row>
        <Grid.Row>
            <Grid.Column />
            <Grid.Column width={12}>
                <Header as='h2'>
                     <span style={{color:"white"}}>AutoTube</span>
                </Header>
                <p>I wanted to see if it was possible to automate a successful Youtube channel and make it completely hands free. </p>
                <Header as='h3' dividing color='teal'>
                    Tech Stack
                </Header>
                <List>
                    <List.Item>
                        <List.Icon name='marker' color='teal'/>
                        <List.Content>
                            Python 
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='marker' color='teal'/>
                        <List.Content>
                            MoviePy / Youtube API / Google Text to Speech 
                        </List.Content>
                    </List.Item>                       
                </List>
                <Header as='h3' dividing color='teal'>
                    Features
                </Header>
                <List>
                    <List.Item>
                        <List.Icon name='marker' color='teal'/>
                        <List.Content>
                            Grab top 10 Google trends for the day
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='marker' color='teal'/>
                        <List.Content>
                            Grab an image from Google image search for each trending topic 
                        </List.Content>
                    </List.Item>  
                    <List.Item>
                        <List.Icon name='marker' color='teal'/>
                        <List.Content>
                            Build a 1 minute video displaying an image along with highlight 
                        </List.Content>
                    </List.Item>                       
                    <List.Item>
                        <List.Icon name='marker' color='teal'/>
                        <List.Content>
                            Google text to speech read the highlight for each image  
                        </List.Content>
                    </List.Item>  
                </List>
                <Header as='h3' dividing color='teal'>
                    Repositories
                </Header>
                <List>
                    <List.Item>                    
                        <List.Icon name='github' color='teal'/>
                        <List.Content>
                            <a href="https://github.com/joannpav/autotube_v2"  style={{color:"white"}}> Github - email me for access</a>
                        </List.Content>
                    </List.Item>                    
                </List>
                
                <Header as='h3' dividing color='teal'>
                    Screenshots
                </Header>
                <Image src='/autotube/autotube.png' wrapped ui={true} />
                <Header as='h3' dividing color='teal'>
                    Motivation / Challenges
                </Header>
                <Item.Group relaxed>
                    <Item>
                        <Item.Content>
                            <Item.Header style={{color:"white"}}>Motivation</Item.Header>
                            <Item.Description style={{color:"white", fontSize:"medium"}}>
                                <p>
                                    This was more of a fun experiment and a chance to learn about MoviePy, Google text to speech and the Youtube API.
                                    While it would have been fun to see my videos take off n popularity, I wasn't expecting that to happen. I feel like
                                    it could be possible to automate a popular channel with the right content... and it is probably already how some popular channels are 
                                    already creating their content. 
                                </p>
                                
                            </Item.Description>
                        </Item.Content>
                    </Item>
                    <Item>
                        <Item.Content>
                            <Item.Header style={{color:"white"}}>Challenges</Item.Header>
                            <Item.Description>
                            <List.Item>                                
                                <List.Content style={{color:"white", fontSize:"medium"}}>
                                    <p>
                                    Before I discovered the MoviePy library, I was using OpenCV and Numpy which were a lot more challenging.
                                    </p>
                                    <p>The most views I received was 20. So not really a success, but I bet this could be converted to a niche topic and see better results.
                                        I'd probably need more variety in the title and more 'pop' in the thumbnail.
                                    </p>
                                </List.Content>
                            </List.Item>
                            </Item.Description>
                        </Item.Content>
                    </Item>
                </Item.Group>
            </Grid.Column>
            <Grid.Column >                
                     
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column />
            <Grid.Column />
            <Grid.Column >                
                
            </Grid.Column>                
        </Grid.Row>
    </Grid>
    )


}

export default AutoTube;