import React from "react";
import { Header, Container, Image, List, Grid } from 'semantic-ui-react';

function Work() {
    return (  
        <Container className="App-header" style={{width: 'auto'}}>
        <Grid    style={{marginTop:'unset',  padding:'20px'}} columns='equal'>
            <Grid.Row>
            <Grid.Column >
                    <Container text >
                    <List>
                        <List.Item>
                        <List.Icon name='users' />
                        <List.Content>Director, Quality / Principal SDET</List.Content>
                        </List.Item>
                        <List.Item>
                        <List.Icon name='marker' />
                        <List.Content>Remote</List.Content>
                        </List.Item>
                        <List.Item>
                        <List.Icon name='mail' />
                        <List.Content>
                            joannpav at gmail.com
                        </List.Content>
                        </List.Item>
                        <List.Item>
                        <List.Icon name='linkedin' />
                        <List.Content>
                            <a href='https://www.linkedin.com/in/joannp/' style={{color:"white"}}>Linkedin</a>
                        </List.Content>
                        </List.Item>
                    </List>
                </Container>
                </Grid.Column>
                <Grid.Column />
                <Grid.Column >                
                    
                </Grid.Column>                
            </Grid.Row>
            <Grid.Row>
                <Grid.Column />
                <Grid.Column width={12}>
                    <Header as='h2'>
                        <Image circular src='filevine.png' /> <span style={{color:"white"}}>Filevine</span>
                    </Header>
                    <p>As the Director of Quality, I've been building the Quality team, driving automation initiatives and improving process.</p>
                    <Header as='h3' dividing color='teal'>
                        Highlights
                    </Header>
                    <List>
                        <List.Item>
                            <List.Icon name='marker' color='teal'/>
                            <List.Content>
                                I have grown the team to 22 team members consisting of SDETs, Analysts, an architect as well as Escalation Software Engineers.
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Icon name='marker' color='teal'/>
                            <List.Content>
                                Conceptualized and drove an initiative to build data on the fly for test automation to support a future 
                                ephemeral environments and continuous delivery.
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Icon name='marker' color='teal'/>
                            <List.Content>
                                Pulled together the top SDETs and initiated an ambitious effort to reduce the execution time of an automated suite of over 1200 tests
                                from almost 2 hours to under 10 minutes.
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Icon name='marker' color='teal'/>
                            <List.Content>
                                Implemented Allure reporting which reduced friction when diagnosing and triaging failed tests. 
                            </List.Content>
                        </List.Item>
                    </List>
                </Grid.Column>
                <Grid.Column >                
                         
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column />
                <Grid.Column />
                <Grid.Column >                
                    
                </Grid.Column>                
            </Grid.Row>
        </Grid>
        </Container>             
    )
}


export default Work;